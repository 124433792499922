import {
  ClipboardDocumentListIcon,
  DocumentChartBarIcon,
} from '@heroicons/vue/24/outline';

export const NavigationConfig = Object.freeze({
  main: [
  // { name: 'Dashboard', href: '/', icon: Squares2X2Icon },
    { name: 'Orders', href: '/orders', icon: ClipboardDocumentListIcon },
    { name: 'Invoices', href: '/invoices', icon: DocumentChartBarIcon },
  // { name: 'Settings', href: '/settings', icon: Cog8ToothIcon }
  ],
  tools: [],
});
